import * as React from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";

// markup
const IndexPage = () => (
  <Layout>
    <h3>Songs Before Sunrise EP</h3>
    <StaticImage
      src="../images/songs_before_sunrise_cover_2022.jpg"
      alt="Songs Before Sunrise cover"
      width={300}
    />
    <h5>
      Re-released, out now on: Spotify, Apple Music, YT Music, and the rest.
    </h5>

    {/* <title>davepretty.com</title>
    <h1>:)</h1>
    <Link to="/music"> ... </Link> */}
  </Layout>
);
export default IndexPage;
